import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

class PageTemplate extends React.Component {
  render() {
    const post = this.props.data.sitePage;
    const siteTitle = this.props.data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <div dangerouslySetInnerHTML={{ __html: post.context.html }} />
        <hr />
      </Layout>
    );
  }
}

export default PageTemplate;

export const pageQuery = graphql`
  query PageByPath($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    sitePage(path: { eq: $slug }) {
      context {
        html
      }
    }
  }
`;
